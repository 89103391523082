import { Table } from '@radix-ui/themes';

import { registrationFees } from '../data/constants';

const FeesTable = () => {
  return (
    <Table.Root variant='surface' layout='auto'>
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeaderCell>Type</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>
            Early Bird Registration Fee
            <br />
            (Before July 31, 2024)
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>
            Registration Fee
            <br />
            (August 15, 2024)
          </Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {registrationFees.map((fee, index) => (
          <Table.Row key={index}>
            <Table.RowHeaderCell>{fee?.type}</Table.RowHeaderCell>
            <Table.Cell>{fee?.earlyBirdRegistraionFee}</Table.Cell>
            <Table.Cell>{fee?.registrationFee}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  );
};

export default FeesTable;
