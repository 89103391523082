import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import About from './pages/About';
import Schedule from './pages/Schedule';
import Register from './pages/Register';
import Attend from './pages/Attend';
import Instructors from './pages/Instructors';
import Layout from './components/Layout';
import OrganizingCommitee from './pages/OrganizingCommitee';

export const App = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={
              <Layout>
                <About />
              </Layout>
            }
          />
          <Route
            path='/schedule'
            element={
              <Layout>
                <Schedule />
              </Layout>
            }
          />
          <Route
            path='/attend'
            element={
              <Layout>
                <Attend />
              </Layout>
            }
          />
          <Route
            path='/register'
            element={
              <Layout>
                <Register />
              </Layout>
            }
          />
          <Route
            path='/instructors'
            element={
              <Layout>
                <Instructors />
              </Layout>
            }
          />
          <Route
            path='/organizers'
            element={
              <Layout>
                <OrganizingCommitee />
              </Layout>
            }
          />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
