import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from '@material-tailwind/react';
import { useState } from 'react';
import { Container, Flex } from '@radix-ui/themes';

import FeesTable from '../components/FeesTable';
import BankDetails from '../components/BankDetails';
import IndividualRegistration from '../components/IndividualRegistration';
import GroupRegistration from '../components/GroupRegistration';

const Register = () => {
  const [bankAndFeesActiveTab, setBankAndFeesActiveTab] = useState(1);
  const [registrationTypeActiveTab, setRegistrationTypeActiveTab] = useState(1);

  return (
    <Container
      size='4'
      width='100%'
      align='center'
      py={{ initial: '6', md: '8' }}
      px={{ initial: '4', md: '0' }}
    >
      <Flex
        width='100%'
        align='center'
        direction='column'
        gap={{ initial: '6', md: '8' }}
      >
        <Flex
          width='100%'
          align='start'
          direction='column'
          gap={{ initial: '4', md: '6' }}
        >
          <h1 className='text-primaryColor text-2xl md:text-5xl font-bold'>
            Event Registration
          </h1>

          <p className='text-sm md:text-base w-full text-justify'>
            {`Due to the intensive and scientific nature of this workshop, which includes hands-on problem-solving, case studies, and interactive sessions, the number of participants has been limited to 50 to ensure effective learning outcome.`}
            <br />
            <strong>Note:</strong>{' '}
            {`Please transfer the event registration fee to the following account and upload a copy of the transaction details along with registration below. Registrations without a valid transaction will be discarded.`}
          </p>

          <Tabs value={bankAndFeesActiveTab} className='w-full'>
            <TabsHeader
              className='rounded-none border-b-0 bg-transparent p-0'
              indicatorProps={{
                className:
                  'bg-transparent border-b-2 border-primaryColor shadow-none rounded-none',
              }}
            >
              <Tab
                value={1}
                onClick={() => setBankAndFeesActiveTab(1)}
                className={`text-lg md:text-xl ${
                  bankAndFeesActiveTab === 1
                    ? 'text-primaryColor font-bold'
                    : 'text-blackColor'
                }`}
              >
                Bank Details
              </Tab>
              <Tab
                value={2}
                onClick={() => setBankAndFeesActiveTab(2)}
                className={`text-lg md:text-xl ${
                  bankAndFeesActiveTab === 2
                    ? 'text-primaryColor font-bold'
                    : 'text-blackColor'
                }`}
              >
                Registration Fees
              </Tab>
            </TabsHeader>

            <TabsBody className='mt-4'>
              <TabPanel value={1}>
                <BankDetails />
              </TabPanel>

              <TabPanel value={2}>
                <FeesTable />
              </TabPanel>
            </TabsBody>
          </Tabs>

          <Tabs value={registrationTypeActiveTab} className='w-full'>
            <TabsHeader
              className='rounded-none border-b-0 bg-transparent p-0'
              indicatorProps={{
                className:
                  'bg-transparent border-b-2 border-primaryColor shadow-none rounded-none',
              }}
            >
              <Tab
                value={1}
                onClick={() => setRegistrationTypeActiveTab(1)}
                className={`text-lg md:text-xl ${
                  registrationTypeActiveTab === 1
                    ? 'text-primaryColor font-bold'
                    : 'text-blackColor'
                }`}
              >
                Individual Registration
              </Tab>
              <Tab
                value={2}
                onClick={() => setRegistrationTypeActiveTab(2)}
                className={`text-lg md:text-xl ${
                  registrationTypeActiveTab === 2
                    ? 'text-primaryColor font-bold'
                    : 'text-blackColor'
                }`}
              >
                Group Registration
              </Tab>
            </TabsHeader>

            <TabsBody className='mt-4'>
              <TabPanel value={1}>
                <IndividualRegistration />
              </TabPanel>

              <TabPanel value={2}>
                <GroupRegistration />
              </TabPanel>
            </TabsBody>
          </Tabs>
        </Flex>
      </Flex>
    </Container>
  );
};

export default Register;
