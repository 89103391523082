import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Flex, Grid } from '@radix-ui/themes';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Navbar, Collapse, IconButton } from '@material-tailwind/react';

const NavItem = ({ path, title }) => {
  const { pathname } = useLocation();

  return (
    <Link
      to={path}
      className={`text-whiteColor text-xl font-medium ${
        pathname === path ? 'underline underline-offset-4' : ''
      }`}
    >
      {title}
    </Link>
  );
};

const Header = () => {
  const [openNav, setOpenNav] = useState(false);

  return (
    <Container
      size='4'
      width='100%'
      align='center'
      className='bg-primaryColor z-50 w-full top-0 sticky py-4 max-h-20'
    >
      <Flex
        direction='row'
        align='center'
        justify='between'
        className='hidden md:flex w-full'
      >
        <Link to='/'>
          <img
            src='/assets/logos/BITSLogoShort.png'
            alt='BITS_logo'
            className='h-auto w-[50px]'
          />
        </Link>

        <Flex direction='row' align='center' gap='8'>
          <NavItem path='/' title='About' />
          <NavItem path='/schedule' title='Schedule' />
          <NavItem path='/attend' title='Attend' />
          <NavItem path='/register' title='Register' />
          <NavItem path='/instructors' title='Instructors' />
          <NavItem path='/organizers' title='Organizing Commitee' />
        </Flex>
      </Flex>

      <Navbar className='rounded-none px-4 py-3 block md:hidden bg-primaryColor border-none -mt-4'>
        <div className='flex items-center justify-between text-blue-gray-900'>
          <Link to='/'>
            <img
              src='/assets/logos/BITSLogoShort.png'
              alt='BITS_logo'
              className='h-auto w-[40px] mr-4 py-1.5'
            />
          </Link>

          <IconButton
            variant='text'
            className='ml-auto h-6 w-6 text-whiteColor'
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <XMarkIcon className='h-6 w-6' strokeWidth={2} />
            ) : (
              <Bars3Icon className='h-6 w-6' strokeWidth={2} />
            )}
          </IconButton>
        </div>

        <Collapse open={openNav}>
          <Grid
            width='100%'
            columns='2'
            gap='4'
            className='place-items-center my-2'
            onClick={() => setOpenNav(!openNav)}
          >
            <NavItem path='/' title='About' />
            <NavItem path='/schedule' title='Schedule' />
            <NavItem path='/attend' title='Attend' />
            <NavItem path='/register' title='Register' />
            <NavItem path='/instructors' title='Instructors' />
            <NavItem path='/organizers' title='Organizers' />
          </Grid>
        </Collapse>
      </Navbar>
    </Container>
  );
};

export default Header;
