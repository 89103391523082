import { Table } from '@radix-ui/themes';

import { bankDetails } from '../data/constants';

const BankDetails = () => {
  return (
    <Table.Root variant='surface' layout='auto'>
      <Table.Body>
        {bankDetails.map((bankDetail, index) => (
          <Table.Row key={index}>
            <Table.RowHeaderCell>{bankDetail?.name}</Table.RowHeaderCell>

            <Table.Cell>{bankDetail?.value}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  );
};

export default BankDetails;
