const individualRegistrationFieldsPersonalDetails = [
  'Personal Details',
  [
    {
      name: 'title',
      label: 'Title',
      fieldType: 'dropdown',
      dropdownOptions: [
        { label: 'Dr.', value: 'dr' },
        { label: 'Mr.', value: 'mr' },
        { label: 'Ms.', value: 'ms' },
      ],
      type: 'text',
    },
    {
      name: 'name',
      label: 'Full Name',
      fieldType: 'input',
      type: 'text',
    },
    {
      name: 'department',
      label: 'Department',
      fieldType: 'input',
      type: 'text',
    },
    {
      name: 'organization',
      label: 'Organization / Institution',
      fieldType: 'input',
      type: 'text',
    },
    {
      name: 'organizationType',
      label: 'Organization Type',
      fieldType: 'dropdown',
      dropdownOptions: [
        { label: 'Industry', value: 'industry' },
        { label: 'Academia', value: 'academia' },
      ],
      type: 'text',
    },
    {
      name: 'email',
      label: 'Email',
      fieldType: 'input',
      type: 'email',
    },
    {
      name: 'mobile',
      label: 'Mobile Number',
      fieldType: 'input',
      type: 'tel',
    },
    {
      name: 'participantType',
      label: 'Participant Type',
      fieldType: 'dropdown',
      dropdownOptions: [
        { label: 'Industry Professional', value: 'industryProfessional' },
        { label: 'Academic Staff', value: 'academicStaff' },
        { label: 'Doctoral Student', value: 'doctoralStudent' },
        {
          label: 'Undergraduate / Postgraduate Student',
          value: 'graduateStudent',
        },
      ],
      type: 'text',
    },
    {
      //Optional field
      name: 'requirements',
      label: 'Special Requirements (Dietary, Accessibility, etc.)',
      fieldType: 'input',
      type: 'text',
      required: false,
    },
    {
      name: 'proofOfTransaction',
      label: 'Upload proof of transaction',
      fieldType: 'fileInput',
      type: 'file',
      accept: '.png, .jpg, .jpeg, .pdf',
    },
  ],
];

//Section header : Emergency Contact Details
const individualRegistrationFieldsEmergencyContact = [
  'Emergency Contact',
  [
    {
      //Optional field
      name: 'emergencyName',
      label: 'Name',
      fieldType: 'input',
      type: 'text',
      required: false,
    },
    {
      //Optional field
      name: 'emergencyRelation',
      label: 'Relation',
      fieldType: 'input',
      type: 'text',
      required: false,
    },
    {
      //Optional field
      name: 'emergencyMobile',
      label: 'Mobile Number',
      fieldType: 'input',
      type: 'tel',
      required: false,
    },
  ],
];

export const individualRegistrationFields = [
  individualRegistrationFieldsPersonalDetails,
  individualRegistrationFieldsEmergencyContact,
];

//Section Header: Participant 1 Details
const groupRegistrationFieldsParticipantOne = [
  'Participant One',
  [
    {
      name: 'participantOneTitle',
      label: 'Title',
      fieldType: 'dropdown',
      dropdownOptions: [
        { label: 'Dr.', value: 'dr' },
        { label: 'Mr.', value: 'mr' },
        { label: 'Ms.', value: 'ms' },
      ],
      type: 'text',
    },
    {
      name: 'participantOneName',
      label: 'Full Name',
      fieldType: 'input',
      type: 'text',
    },
    {
      name: 'participantOneEmail',
      label: 'Email',
      fieldType: 'input',
      type: 'email',
    },
    {
      name: 'participantOneMobile',
      label: 'Mobile Number',
      fieldType: 'input',
      type: 'tel',
    },
  ],
];

const groupRegistrationFieldsParticipantTwo = [
  //Section Header: Participant 2 Details
  'Participant Two',
  [
    {
      name: 'participantTwoTitle',
      label: 'Title',
      fieldType: 'dropdown',
      dropdownOptions: [
        { label: 'Dr.', value: 'dr' },
        { label: 'Mr.', value: 'mr' },
        { label: 'Ms.', value: 'ms' },
      ],
      type: 'text',
    },
    {
      name: 'participantTwoName',
      label: 'Full Name',
      fieldType: 'input',
      type: 'text',
    },
    {
      name: 'participantTwoEmail',
      label: 'Email',
      fieldType: 'input',
      type: 'email',
    },
    {
      name: 'participantTwoMobile',
      label: 'Mobile Number',
      fieldType: 'input',
      type: 'tel',
    },
  ],
];

//Section Header: Participant 3 Details
const groupRegistrationFieldsParticipantThree = [
  'Participant Three',
  [
    {
      name: 'participantThreeTitle',
      label: 'Title',
      fieldType: 'dropdown',
      dropdownOptions: [
        { label: 'Dr.', value: 'dr' },
        { label: 'Mr.', value: 'mr' },
        { label: 'Ms.', value: 'ms' },
      ],
      type: 'text',
    },
    {
      name: 'participantThreeName',
      label: 'Full Name',
      fieldType: 'input',
      type: 'text',
    },
    {
      name: 'participantThreeEmail',
      label: 'Email',
      fieldType: 'input',
      type: 'email',
    },
    {
      name: 'participantThreeMobile',
      label: 'Mobile Number',
      fieldType: 'input',
      type: 'tel',
    },
  ],
];

//Section Header: Organization Details
const groupRegistrationFieldsOrganizationDetails = [
  'Organization Details',
  [
    {
      name: 'organization',
      label: 'Organization / Institution',
      fieldType: 'input',
      type: 'text',
    },
    {
      name: 'organizationType',
      label: 'Organization Type',
      fieldType: 'dropdown',
      dropdownOptions: [
        { label: 'Industry', value: 'industry' },
        { label: 'Academia', value: 'academia' },
      ],
      type: 'text',
    },
    {
      name: 'organizationContactName',
      label: 'Contact Name',
      fieldType: 'input',
      type: 'text',
    },
    {
      name: 'organizationContactRelation',
      label: 'Designation',
      fieldType: 'input',
      type: 'text',
    },
    {
      name: 'organizationContactEmail',
      label: 'Organization Email',
      fieldType: 'input',
      type: 'email',
    },
    {
      name: 'organizationContactMobile',
      label: 'Organization Mobile Number',
      fieldType: 'input',
      type: 'tel',
    },
  ],
];

//Section Header: Other Details
const groupRegistrationFieldsOtherDetails = [
  'Other Details',
  [
    {
      //Optional field
      name: 'requirements',
      label: 'Special Requirements (Dietary, Accessibility, etc.)',
      fieldType: 'input',
      type: 'text',
      required: false,
    },
    {
      name: 'proofOfTransaction',
      label: 'Upload proof of transaction',
      fieldType: 'fileInput',
      type: 'file',
      accept: '.png, .jpg, .jpeg, .pdf',
    },
  ],
];

export const groupRegistrationFields = [
  groupRegistrationFieldsParticipantOne,
  groupRegistrationFieldsParticipantTwo,
  groupRegistrationFieldsParticipantThree,
  groupRegistrationFieldsOrganizationDetails,
  groupRegistrationFieldsOtherDetails,
];
