import {
  Button,
  Input,
  Option,
  Select,
  Spinner,
} from '@material-tailwind/react';
import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadString,
} from 'firebase/storage';
import { toast } from 'sonner';
import { useState } from 'react';
import { Flex, Grid } from '@radix-ui/themes';
import emailjs from '@emailjs/browser';

import {
  checkDuplicateEntry,
  generateQrCodeAsDataUrl,
  hashValue,
} from '../helper/helper_functions';
import { firestore, storageInstance } from '../services/firebase';
import { groupRegistrationFields } from '../data/registerForm';
import { doc, setDoc } from 'firebase/firestore';

const GroupRegistration = () => {
  const currentYear = new Date().getFullYear();
  const [loading, setLoading] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedObject.participantOneTitle) {
      toast.error('Please select a title for participant one');
      return;
    } else if (!selectedObject.participantTwoTitle) {
      toast.error('Please select a title for participant two');
      return;
    } else if (!selectedObject.participantThreeTitle) {
      toast.error('Please select a title for participant three');
      return;
    } else if (!selectedObject.organizationType) {
      toast.error('Please select an organization type');
      return;
    }

    setLoading(true);

    const formData = new FormData(e.target);
    var data = Object.fromEntries(formData.entries());

    // Set registration type to individual
    data.registrationType = 'group';

    data = {
      ...data,
      ...selectedObject,
    };

    try {
      const isDuplicateOne = await checkDuplicateEntry({
        email: data.participantOneEmail,
      });
      const isDuplicateTwo = await checkDuplicateEntry({
        email: data.participantTwoEmail,
      });
      const isDuplicateThree = await checkDuplicateEntry({
        email: data.participantThreeEmail,
      });
      if (isDuplicateOne || isDuplicateTwo || isDuplicateThree) {
        toast.error('A registration with one of the same email already exists');
        setLoading(false);
        return;
      }

      //set main hash value from participant one email
      data.hashValue = await hashValue(data.participantOneEmail);

      //hashed ids for 3 participants
      const participantsDetails = {
        participantOneDetails: {
          hashedId: await hashValue(data.participantOneEmail),
          qrCodeDownloadUrl: '',
        },
        participantTwoDetails: {
          hashedId: await hashValue(data.participantTwoEmail),
          qrCodeDownloadUrl: '',
        },
        participantThreeDetails: {
          hashedId: await hashValue(data.participantThreeEmail),
          qrCodeDownloadUrl: '',
        },
      };

      //qr codes for 3 participants
      //participant one
      const participantOneQrCodeBase64 = generateQrCodeAsDataUrl(
        participantsDetails.participantOneDetails.hashedId
      );
      const participantOneQrCodeRef = ref(
        storageInstance,
        `qrCodes/${currentYear}/qbd/${data.participantOneEmail}.png`
      );
      await uploadString(
        participantOneQrCodeRef,
        participantOneQrCodeBase64,
        'data_url'
      );
      participantsDetails.participantOneDetails.qrCodeDownloadUrl =
        await getDownloadURL(participantOneQrCodeRef);

      //participant two
      const participantTwoQrCodeBase64 = generateQrCodeAsDataUrl(
        participantsDetails.participantTwoDetails.hashedId
      );
      const participantTwoQrCodeRef = ref(
        storageInstance,
        `qrCodes/${currentYear}/qbd/${data.participantTwoEmail}.png`
      );
      await uploadString(
        participantTwoQrCodeRef,
        participantTwoQrCodeBase64,
        'data_url'
      );
      participantsDetails.participantTwoDetails.qrCodeDownloadUrl =
        await getDownloadURL(participantTwoQrCodeRef);

      //participant three
      const participantThreeQrCodeBase64 = generateQrCodeAsDataUrl(
        participantsDetails.participantThreeDetails.hashedId
      );
      const participantThreeQrCodeRef = ref(
        storageInstance,
        `qrCodes/${currentYear}/qbd/${data.participantThreeEmail}.png`
      );
      await uploadString(
        participantThreeQrCodeRef,
        participantThreeQrCodeBase64,
        'data_url'
      );
      participantsDetails.participantThreeDetails.qrCodeDownloadUrl =
        await getDownloadURL(participantThreeQrCodeRef);

      //adding participantsDetails map to data
      data.participantsDetails = participantsDetails;

      //uploading proof of transaction
      const proofOfTransactionRef = ref(
        storageInstance,
        `files/${currentYear}/qbd/${data.email}.png`
      );
      await uploadBytes(proofOfTransactionRef, data.proofOfTransaction);
      data.proofOfTransactionUrl = await getDownloadURL(proofOfTransactionRef);

      const { proofOfTransaction, ...dataToSend } = data;

      await setDoc(
        doc(
          firestore,
          `registrations/${currentYear}/qbd`,
          dataToSend.hashValue
        ),
        dataToSend
      );

      await emailjs
        .send(
          'service_t8clwjo',
          'template_5dqi3of',
          {
            participantOneName: dataToSend.participantOneName,
            participantOneMobile: dataToSend.participantOneMobile,
            participantOneEmail: dataToSend.participantOneEmail,
            participantTwoName: dataToSend.participantTwoName,
            participantTwoMobile: dataToSend.participantTwoMobile,
            participantTwoEmail: dataToSend.participantTwoEmail,
            participantThreeName: dataToSend.participantThreeName,
            participantThreeMobile: dataToSend.participantThreeMobile,
            participantThreeEmail: dataToSend.participantThreeEmail,
            organization: dataToSend.organization,
            participantOneQrCodeUrl:
              data.participantsDetails.participantOneDetails.qrCodeDownloadUrl,
            participantTwoQrCodeUrl:
              data.participantsDetails.participantTwoDetails.qrCodeDownloadUrl,
            participantThreeQrCodeUrl:
              data.participantsDetails.participantThreeDetails
                .qrCodeDownloadUrl,
          },
          'V2Yz1dwEESLY22zXz'
        )
        .then(() => {
          toast.success(
            'Registration successful! Please check your email for a confirmation'
          );
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        });
    } catch (error) {
      toast.error('An error occurred. Please try again');
      console.error('Error in group registration: ', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Flex
        width='100%'
        align='start'
        justify='center'
        direction='column'
        gap={{ initial: '4', md: '6' }}
      >
        {groupRegistrationFields.map((section, index) => (
          <Flex
            key={index}
            width='100%'
            align='start'
            justify='center'
            direction='column'
            gap={{ initial: '4', md: '6' }}
          >
            <h1 className='text-primaryColor text-xl md:text-3xl font-bold'>
              {section[0]}
            </h1>

            <Grid
              width='100%'
              align='center'
              gap={{ initial: '4', md: '6' }}
              columns={{ initial: '1', sm: '2' }}
            >
              {section[1].map((field, index) => {
                if (field.fieldType === 'dropdown')
                  return (
                    <Select
                      key={index}
                      label={field.label}
                      className='w-full text-primaryColor'
                      value={selectedObject[field.name]}
                      onChange={(c) => {
                        setSelectedObject({
                          ...selectedObject,
                          [field.name]: c,
                        });
                      }}
                    >
                      {field?.dropdownOptions?.map((option, index) => (
                        <Option key={index} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  );
                else
                  return (
                    <Input
                      className={
                        field.type === 'file'
                          ? 'flex w-full border border-input text-sm transition-colors file:border-0 file:bg-gray-100 file:rounded-md file:text-sm file:font-medium'
                          : 'w-full text-primaryColor'
                      }
                      key={index}
                      type={field.type}
                      label={field.label}
                      name={field.name}
                      accept={field.accept ?? undefined}
                      required={field.required === false ? false : true}
                    />
                  );
              })}
            </Grid>
          </Flex>
        ))}

        <Button
          type='submit'
          className='bg-primaryColor px-10'
          disabled={loading}
        >
          {loading ? <Spinner className='w-full h-full' /> : 'Submit'}
        </Button>
      </Flex>
    </form>
  );
};

export default GroupRegistration;
